if (document.querySelector('.temp-cards')) {
  let tempDiv = document.querySelector('.temp-cards');
  let topDiv = document.querySelector('.top');
  let botDiv = document.querySelector('.bottom');
  let tempLinks = tempDiv.querySelectorAll('a');
  let halfLength = Math.ceil(tempLinks.length / 2);

  if (tempLinks.length > 10) {
    for (let i = 0; i < tempLinks.length; i++) {
      if (i < halfLength) {
        topDiv.appendChild(tempLinks[i]);
      } else {
        botDiv.appendChild(tempLinks[i]);
      }
    }
  } else if (tempLinks.length == 0) {
    let noElements = document.createElement('p')
    noElements.style.fontSize = '22px'
    noElements.innerHTML = 'Sorry, No Products Matched Your Criteria'
    topDiv.appendChild(noElements)
  } else {
    for (let i = 0; i < tempLinks.length; i++) {
      topDiv.appendChild(tempLinks[i]);
    }
  }
}