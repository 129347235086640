window.addEventListener('load', () => {
  $('.card-slider').slick({
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          swipe: false,
        },
      },
    ],
  })

  let cards = document.querySelectorAll('.card')
  cards.forEach((element) => {
    let arrows = element.querySelectorAll('.slick-arrow')
    arrows.forEach((el) => {
      el.addEventListener('click', (event) => {
        event.preventDefault()
      })
    })
  })

  $('.product__slider').slick({
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: '.image__preview',
    infinite: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          arrows: false,
          dots: true,
          // centerMode: true,
        },
      },
    ],
  })

  $('.image__preview').slick({
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 1,
    vertical: true,
    arrows: false,
    asNavFor: '.product__slider',
    centerMode: true,
    infinite: false,
    focusOnSelect: true,
  })

  // mobile slider
  window.addEventListener('resize', () => {
    if (window.innerWidth < 991) {
      $('.catalog__inner').slick({
        speed: 300,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
        infinite: false,
      })
    } else {
      $('.catalog__inner').slick('unslick')
    }
  })
})
