if (document.querySelector('.shop-filter')) {
  let shopFilter = document.querySelector('.shop-filter')
  let filterItems = shopFilter.querySelectorAll('.dropdown__list-item')
  let hiddenForm = document.querySelector('.form-hidden')
  let orderBy = hiddenForm.querySelector('input[name="orderby"]')
  let order = hiddenForm.querySelector('input[name="order"]')
  let size = hiddenForm.querySelector('input[name="size"]')
  let color = hiddenForm.querySelector('input[name="color"]')
  let priceMin = hiddenForm.querySelector('input[name="priceMin"]')
  let priceMax = hiddenForm.querySelector('input[name="priceMax"]')
  let filtersQuantity = document.querySelector('.filters-quantity')
  let colors = []
  let sizes = []
  filterItems.forEach(element => {
    element.addEventListener('click', (el) => {
      let checkUndef = (data, value) => {
        let sizes = []
        let colors = []
        if (value !== undefined) {
          if (data == size) {
            let parent = el.target.closest('.dropdown__list')
            let checked = parent.querySelectorAll('.checked')
            if (checked.length > 0) {
              checked.forEach(element => {
                sizes.push(element.getAttribute('data-size'))
              });
            }
            data.value = sizes
          } else if (data == color) {
            let parent = el.target.closest('.dropdown__list')
            let checked = parent.querySelectorAll('.checked')
            if (checked.length > 0) {
              checked.forEach(element => {
                colors.push(element.getAttribute('data-color'))
              });
            }
            data.value = colors
          }
          else {
            data.value = value
          }
        }
      }
      checkUndef(orderBy, el.target.dataset.orderby)
      checkUndef(color, el.target.dataset.color)
      checkUndef(order, el.target.dataset.order)
      checkUndef(size, el.target.dataset.size)
      checkUndef(priceMin, el.target.dataset.pricemin)
      checkUndef(priceMax, el.target.dataset.pricemax)

      let atributes = ['orderby', 'size', 'color', 'priceMin']

      for (let i = 0; i < atributes.length; i++) {
        let selectedWrapper = document.querySelector('.selected-wrapper')
        let target = el.target.getAttribute(`data-${atributes[i]}`)
        let selectedFilter = document.createElement('p')
        selectedFilter.classList.add('selected')
        if (target !== null) {
          if (atributes[i] == 'orderby') {
            selectedFilter.innerHTML = `${element.innerHTML}`
            selectedFilter.id = atributes[i]

            if (document.querySelector('p#orderby')) {
              let order = document.querySelector('p#orderby')
              order.innerHTML = `${element.innerHTML}`
            } else {
              selectedWrapper.append(selectedFilter)
            }
          }
          else if (atributes[i] == 'size') {
            selectedFilter.innerHTML = `${element.innerHTML}`
            selectedFilter.id = atributes[i]

            if (!sizes.includes(element.innerHTML)) {
              sizes.push(element.innerHTML)
            } else {
              let param = sizes.indexOf(element.innerHTML)
              sizes.splice(param, 1)
            }
            if (document.querySelector('p#size')) {
              let order = document.querySelector('p#size')
              if (sizes.length == 0) {
                order.remove()
              }
              order.innerHTML = sizes
            } else {
              selectedWrapper.append(selectedFilter)
            }
            if (sizes.length == 0) {
              element
            }
          }
          else if (atributes[i] == 'color') {
            selectedFilter.innerHTML = `${element.innerHTML}`
            selectedFilter.id = atributes[i]
            if (!colors.includes(element.innerHTML)) {
              colors.push(element.innerHTML)
            } else {
              let param = colors.indexOf(element.innerHTML)
              colors.splice(param, 1)
            }
            if (document.querySelector('p#color')) {
              let order = document.querySelector('p#color')
              if (colors.length == 0) {
                order.remove()
              }
              order.innerHTML = colors
            } else {
              selectedWrapper.append(selectedFilter)
            }
            if (colors.length == 0) {
              element
            }
          }
          else if (atributes[i] == 'priceMin') {
            selectedFilter.innerHTML = `${element.innerHTML}`
            selectedFilter.id = atributes[i]

            if (document.querySelector('p#priceMin')) {
              let order = document.querySelector('p#priceMin')
              order.innerHTML = `${element.innerHTML}`
            } else {
              selectedWrapper.append(selectedFilter)
            }
          }
          filtersQuantity.innerHTML = selectedWrapper.childElementCount + " selected"
        }
      }
      let activeFilters = getFilters()
      activeFilters.forEach(element => {
        element.addEventListener('click', (el) => {
          let form = document.querySelector('.form-hidden')
          let uncheckFilters = (document.querySelectorAll(`[data-${el.target.id}]`))
          uncheckFilters.forEach(element => {
            element.classList.remove('checked')
          });
          let selectedWrapper = document.querySelector('.selected-wrapper')
          form.querySelector(`input[name="${el.target.id}`).value = ''
          if (el.target.id == 'priceMin') {
            document.querySelector('input[name=priceMax]').value = ''
          }
          if (el.target.id == 'orderby') {
            document.querySelector('input[name=orderby]').value = ''
          }
          el.target.remove()


          filtersQuantity.innerHTML = selectedWrapper.childElementCount + " selected"
        })
      });

    });
  })

  let getFilters = () => {
    let selectedFilters = document.querySelectorAll('.selected')
    return selectedFilters
  }

  // передача в адресную строку

  window.addEventListener('load', () => {
    let urlParams = new URLSearchParams(window.location.search)
    let colorURL = urlParams.get('color')
    let sizeURL = urlParams.get('size')
    let orderURL = urlParams.get('order')
    let orderByURL = urlParams.get('orderby')
    let priceMinURL = urlParams.get('priceMin')
    let priceMaxURL = urlParams.get('priceMax')

    if (colorURL !== null && colorURL != "") {
      color.value = colorURL
      let colors = colorURL.split(',')
      if (colors.length > 0) {
        colors.forEach(element => {
          let chosedColor = document.querySelector(`li[data-color="${element}"]`)
          chosedColor.click()
        });
      }
    }
    if (sizeURL !== null && sizeURL != "") {
      size.value = sizeURL
      let sizes = sizeURL.split(',')
      if (sizes.length > 0) {
        sizes.forEach(element => {
          let chosedSize = document.querySelector(`li[data-size="${element}"]`)
          chosedSize.click()
        });
      }
    }
    if (orderURL !== null && orderByURL !== null) {
      order.value = orderURL
      orderBy.value = orderByURL
      let chosedOrder = document.querySelectorAll(`li[data-orderby="${orderByURL}"]`)
      if (chosedOrder.length > 0) {
        chosedOrder.forEach(element => {
          if (element.getAttribute('data-order') == orderURL) {
            element.click()
          }
        });
      }
    }
    if (priceMinURL !== null && priceMaxURL !== null) {
      priceMin.value = priceMinURL
      priceMax.value = priceMaxURL
      let chosedPrice = document.querySelectorAll(`li[data-pricemin="${priceMinURL}"]`)
      if (chosedPrice.length > 0) {
        chosedPrice.forEach(element => {
          if (element.getAttribute('data-pricemax') == priceMaxURL) {
            element.click()
          }
        });
      }
    }
  })
}

if (document.querySelector('.filters-apply')) {
  let filtersApply = document.querySelector('.filters-apply')
  let filtersReset = document.querySelector('.filters-reset')
  filtersApply.addEventListener('click', () => {
    let form = document.querySelector('.form-hidden')
    form.submit()
  })
  filtersReset.addEventListener('click', () => {
    let form = document.querySelector('.form-hidden')
    form.reset()
    let selectedFilters = document.querySelectorAll('.selected')
    selectedFilters.forEach(element => {
      element.click()
    });

  }
  )
}

if (document.querySelector('.open-mobile-filter')) {
  let openMobileFilters = document.querySelector('.open-mobile-filter')
  openMobileFilters.addEventListener('click', () => {
    document.querySelector('.shop-filter').classList.toggle('opened')
  })
}