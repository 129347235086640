document.addEventListener("DOMContentLoaded", function () {  // Don't touch
  // Don't touch
  objectFitImages();
  svg4everybody();
  // End don't touch
  require('./target-blank.js');
  require('./slick.js');
  require('./nav-shop.js');
  require('./tab.js');
  require('./popup.js');
  require('./dropdown.js');
  require('./search.js');
  require('./counter.js');
  require('./checkbox.js');
  require('./cart.js');
  require('./filter.js');
  require('./two-blocks.js');
});
// remove preloader
window.addEventListener('load', function () {
  $('.preloader').fadeOut();

  document.querySelector('html').classList.add('page-loaded');
  AOS.init({
    once: true,
    useClassNames: true,
    duration: 500,
    delay: 200,
    // easing: 'linear',
    // easing: 'ease',
    // easing: 'ease-in',
    // easing: 'ease-out',
    // easing: 'ease-in-out',
    // easing: 'ease-in-back',
    // easing: 'ease-out-back',
    // easing: 'ease-in-out-back',
    // easing: 'ease-in-sine',
    // easing: 'ease-out-sine',
    // easing: 'ease-in-out-sine',
    // easing: 'ease-in-quad',
    // easing: 'ease-out-quad',
    // easing: 'ease-in-out-quad',
    // easing: 'ease-in-cubic',
    // easing: 'ease-out-cubic',
    // easing: 'ease-in-out-cubic',
    // easing: 'ease-in-quart',
    // easing: 'ease-out-quart',
    easing: 'ease-in-out-quart',
  });
});
