if (document.querySelector('.tab')) {
    let tabsOpen = document.querySelectorAll('.tab-header')
    tabsOpen.forEach(tabHeader => {
        let tab = tabHeader.parentNode.nextElementSibling
        if (tab) {
            tabHeader.addEventListener('click', () => {
                tabHeader.classList.toggle('opened')
                tab.classList.toggle("closed")
                tabOpen.classList.toggle("opened")
            })
        }
    });
}

// if (document.querySelector('.tab-filter')) {
//     let tabFilters = document.querySelectorAll('.tab-filter')
//     tabFilters.forEach(tabFilter => {
//         let tabs = tabFilter.childNodes
//         tabs.forEach(element => {
//             element.addEventListener('click', () => {
//                 element.classList.add("active")
//             })
//         });
//     });
// }