if (document.querySelector('.popup')) {
    document.addEventListener('click', (el) => {
        if (el.target.classList.contains('open-popup')) {
            el.preventDefault()
            let popup = el.target.nextElementSibling
            let popups = document.querySelectorAll('.popup')
            popups.forEach(element => {
                element.classList.remove('opened')
                document.body.classList.remove('opened')
            });

            popup.classList.add('opened')
            document.body.classList.add('opened')
            let close = popup.querySelector('.close')
            close.addEventListener('click', (el) => {
                el.preventDefault()
                popup.classList.remove('opened')
                document.body.classList.remove('opened')
            })
        }
    })
}

if (document.querySelector('.toggle')) {
    let toggles = document.querySelectorAll('.toggle')
    toggles.forEach(element => {
        let buttons = element.querySelectorAll('button')
        buttons.forEach(el => {
            el.addEventListener('click', (e) => {
                e.preventDefault();
                buttons.forEach(button => {
                    button.classList.remove('active')
                });
                el.classList.add('active')
            })
        });
    });
}