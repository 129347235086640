let counters = document.querySelectorAll('.counter')
if (counters.length > 0) {
    counters.forEach((counter) => {
        let count = counter.querySelector('.count')
        let minus = counter.querySelector('.minus')
        let plus = counter.querySelector('.plus')
        minus.addEventListener('click', () => {
            if (count.value > 1) {
                count.value--;
                console.log(count.value)
            }
        })
        plus.addEventListener('click', () => {
            count.value++;
            console.log(count.value)
        })
    })
}