if (document.querySelector('.dropdown')) {
    document.querySelectorAll('.dropdown').forEach((dropdownWrapper) => {
        let dropdownList = dropdownWrapper.querySelector('.dropdown__list');
        let dropdownItems = dropdownWrapper.querySelectorAll('.dropdown__list-item');
        if (document.querySelector('.dropdown__button')) {
            let dropdownButton = dropdownWrapper.querySelector('.dropdown__button');
            dropdownButton.addEventListener('click', (e) => {
                e.preventDefault()
                if (!dropdownButton.classList.contains('opened')) {
                    dropdownButton.classList.add('opened')
                    dropdownList.classList.add('dropdown__list--visible')
                } else {
                    dropdownButton.classList.remove('opened')
                    dropdownList.classList.remove('dropdown__list--visible')
                }
            })
        }
        dropdownItems.forEach(element => {
            element.addEventListener('click', () => {
                if (document.querySelector('.product')) {
                    let dropdownButton = dropdownWrapper.querySelector('.dropdown__button');
                    dropdownButton.innerText = element.innerText;
                    let input = dropdownButton.nextElementSibling
                    input.value = element.innerText;
                    dropdownButton.classList.remove('opened')
                }
                if (document.querySelector('.shop-filter')) {
                    let dropdownButton = dropdownWrapper.querySelector('.dropdown__button');
                    dropdownButton.classList.remove('opened')
                }
                if (!dropdownList.classList.contains('no-folding')) {
                    dropdownList.classList.remove('dropdown__list--visible')
                }
                if (!dropdownList.classList.contains('multiple-choice')) {
                    dropdownItems.forEach(el => {
                        el.classList.remove('checked')
                    })
                }
                if (element.classList.contains('checked')) {
                    element.classList.remove('checked')
                } else {
                    element.classList.add('checked')
                }
            })
        });
    })
    document.addEventListener('click', (el) => {
        let target = el.target
        let openedDropdowns = document.querySelectorAll('.dropdown')

        if (!target.closest('.dropdown')) {
            openedDropdowns.forEach(element => {
                element.querySelector('.dropdown__button').classList.remove('opened')
                element.querySelector('.dropdown__list').classList.remove('dropdown__list--visible')
            });
        } else if (target.closest('.dropdown')) {
            let openedButton = document.querySelectorAll('.dropdown__button.opened')
            if (openedButton.length > 0) {
                openedButton.forEach(element => {
                    if (element != target) {
                        let dropdown = element.closest('.dropdown')
                        let dropdownList = dropdown.querySelector('.dropdown__list')
                        element.classList.remove('opened')
                        dropdownList.classList.remove('dropdown__list--visible')
                    }
                });
            }
        }
    })
}

