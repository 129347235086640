document.addEventListener('click', (e) => {
    let target = e.target

    if (target.closest('.category-heading')) {
        let category = target.closest('.category')
        if (category.classList.contains('opened')) {
            category.classList.remove('opened')
        } else {
            let categoryOp = document.querySelectorAll('.category.opened')
            if (categoryOp.length > 0) {
                categoryOp.forEach((element) => {
                    element.classList.remove('opened')
                })
            }
            category.classList.add('opened')
        }

    }

    if (!target.closest('.category') || target.classList.contains('category')) {
        let categoryOpened = document.querySelectorAll('.category.opened')
        categoryOpened.forEach(element => {
            element.classList.remove('opened')
            let categoryLists = document.querySelectorAll('.category-list')

            categoryLists.forEach(element => {
                element.classList.add('closed')
            });
        });
    }

    if (target.closest('.category-item') && !target.closest('.subcategory')) {
        let button = target.closest('.category-item')
        button.classList.toggle('opened')

        let activeButtons = document.querySelectorAll('.category-item.opened')
        if (activeButtons.length > 0) {
            activeButtons.forEach(element => {
                if (element !== button) {
                    element.classList.remove('opened')
                }
            });
        }
    }
}
)

// mobile

let openMobileCatalog = document.querySelector('.nav-mobile-open')
let navShop = document.querySelector('.nav-shop')
let mobileCatalog = document.querySelector('.nav-shop__inner')

openMobileCatalog.addEventListener('click', () => {
    navShop.classList.add('opened')
    mobileCatalog.classList.add('opened')
})

let closeMobileCatalog = document.querySelector('.nav-mobile-close')
closeMobileCatalog.addEventListener('click', () => {
    navShop.classList.remove('opened')
    mobileCatalog.classList.remove('opened')
})